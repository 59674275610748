import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { Wrapper, SVG } from './ImageContainer.styled';

export const ImageContainer = (props) => {
  const { src } = props;
  const element = useRef();
  const pathTop = useRef();
  const pathBottom = useRef();
  
  useEffect(() => {
    // console.log(path.current.dataset.pathTo);
    // [pathTop, pathBottom].forEach(el => {
    //   gsap
    //   .timeline({
    //     scrollTrigger: {
    //       trigger: element.current,
    //       start: 'top center',
    //       end: 'bottom top',
    //       scrub: true,
    //       onUpdate: (self) => console.log('progress:', self.progress),
    //     },
    //   })
    //   .to(el.current, {
    //     ease: 'none',
    //     attr: { d: el.current.dataset.pathTo },
    //   });
    // });

  }, []);

  return (
    <Wrapper backgroundImage={src}>
      {/* <SVG
        ref={element}
        top={true}
        width='100%'
        height='100%'
        viewBox='0 0 100 10'
        preserveAspectRatio='none'
      >
        <path
          ref={pathTop}
          class='separator__path path-anim'
          data-path-to='M 0 0 C 40 10 60 10 100 0 L 0 0 Z'
          vector-effect='non-scaling-stroke'
          d='M 0 0 C 40 5 60 5 100 0 L 0 0 Z'
        />
      </SVG> */}
      {/* <SVG
        top={false}
        width='100%'
        height='100%'
        viewBox='0 0 100 10'
        preserveAspectRatio='none'
      >
        <path
          ref={pathBottom}
          class='separator__path path-anim'
          data-path-to='M 0 0 C 40 10 60 10 100 0 L 0 0 Z'
          vector-effect='non-scaling-stroke'
          d='M 0 0 C 40 10 60 10 100 0 L 0 0 Z'
        />
      </SVG> */}
    </Wrapper>
  );
};

export default ImageContainer;
