import styled from 'styled-components';

export const Wrapper = styled.div`
  background-image: url(${ props => props.backgroundImage});
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  position: relative;
  height: auto;
  margin: 20px 2.5vw;
  width: calc(50% - 5vw);
  aspect-ratio: 4/5;
  
  @media screen and (min-width: 650px) {
    width: 100%;
  }
`;

export const SVG = styled.svg`
	display: block;
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	width: 100%;
	height: 150px;
  top: ${props => props.top ? '-1px' : 'unset'};
  bottom: ${props => !props.top ? '-1px' : 'unset'};
	/* fill: #F1EEEB; */
  fill: red;
`;