import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #BDBCB9;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 0;
`;

export const LogoContainer = styled.div`
  display: flex;
  height: auto;
  position: relative;
  margin: 10vw;
  max-width: 450px;
  overflow: hidden;
  width: 100%;
  opacity: 1;
  z-index: 10;

  svg {
    display: block;
    height: auto;
    width: 100%
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 500px;
  width: 400px;
  z-index: 2;

  img {
    display: block;
    transform: scale(0.6);
    opacity: 0;
    height: auto;
    position: absolute;
    width: 100%;
  }
`;

export const ScrollArrow = styled.div`
    margin-top: 10vh;
    padding-bottom: 6rem;
    position: absolute;
    bottom: 13vh;

  &:before {
    content: "";
    width: 2px;
    height: 48px;
    background: #4E7C76;
    position: absolute;
    top: 20px;
    left: 50%;
  } 
  &:after {
    content: "";
    width: 2px;
    background: currentColor;
    position: absolute;
    left: 50%;
    background: #4E7C76;
    height: 10px;
    top: 80px;
  } 
	`;

  export const Top = styled.div`
    background-color: #BDBCB9;
    display: flex;
    height: 200%;
    width: 200%;
    position: absolute;
    transform: rotate(-45deg) translateX(-50%);
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  `;

export const Bottom = styled.div`
background-color: #BDBCB9;
display: flex;
height: 30%;
width: 100%;
position: absolute;
bottom: 0;
left: 0;
right: 0;
z-index: 1;
`;

