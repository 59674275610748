import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  height: auto;
  width: 100%;
`;

export const Title = styled.h2`
  font-family: 'brown-sugar';
  font-size: 52px;
  margin: 5vh 0;
  margin-block-end: 5vh;
  margin-block-start: 5vh;

  @media screen and (min-width: 650px) {
    font-size: 72px;
  }
`;

export const Description = styled.p`
  font-family: 'now-thin'; 
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5vh;

  @media screen and (min-width: 650px) {
    font-size: 18px;
    margin-bottom: 0;
  }
`;