import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 0;

  /* @media (hover: none) {
    display: none;
  } */
`;

export const Inner = styled.div`
  width: 100%;
  height: 4px;
  position: relative;
  background-color: ${(props) => props.backgroundColor};
  transform: scaleX(0);
  transform-origin: 0 50%;

  @media screen and (min-width: 650px) {
    height: 8px;
  }
`;

export default {};
