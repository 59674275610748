import React from 'react';
import { sanitize } from '../../util/xss';
import { useStore } from '../../store';

import { Wrapper, Title, Description } from './AboutUs.styled';

export const AboutUs = (props) => {
  const aboutUsStore = useStore((state) => state.aboutUs);
  return (
    <Wrapper>
      <Title>{aboutUsStore?.header}</Title>
      <Description dangerouslySetInnerHTML={{ __html: sanitize(aboutUsStore?.description) }} />
    </Wrapper>
  );
};

export default AboutUs;
