import React from 'react';
import { useStore } from './store';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Cursor from './component/Cursor';
import ProgressBar from './component/ProgressBar';
import Header from './component/Header';
import Layout from './component/Layout';
import AboutUs from './component/AboutUs';
import ImageContainer from './component/ImageContainer';
import Footer from './component/Footer';
import { FontStyles, Column } from './App.styled';
gsap.registerPlugin(ScrollTrigger);

const App = () => {
  const images = useStore((state) => state.images);
  return (
    <div className='App'>
      <Cursor hoverElements={["[data-bil-link='internal']"]} />
      <FontStyles />
          <ProgressBar color={'#4E7C76'} />
          <Header />
          <Layout>
            <Column>
              <AboutUs />
            </Column>
            <Column>
              {images.map((i, j) => (
                <ImageContainer key={`main-${j}`} src={i} />
              ))}
            </Column>
          </Layout>
          <Footer />
    </div>
  );
};

export default App;
