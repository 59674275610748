import React from 'react';
import { create } from 'zustand';

export const useStore = create((set) => ({
  setLenis: (lenis) => set({ lenis }),
  setIntro: (intro) => set({ intro }),
  intro: false,
  aboutUs: {
    header: 'About Us',
    description:
      "Kelka are creative fashion jewellery specialists with over 12 years experience designing, developing & curating jewellery ranges for numerous high street brands & retailers.<br/><br/><br/>We provide a range of creative services from trend direction to sourced products, offering personalised solutions to clients looking to launch or expand their fashion jewellery category. <br/><br/><br/>Our expert team can work with you to develop bespoke trends & design unique ranges tailored to your brand needs.<br/><br/><br/>Having worked with a broad range of partners, Kelka can help you deliver collections at the quality & price that's right for your customer.",
  },
  images: [
    'images/picture-1.jpg',
    'images/picture-2.jpg',
    'images/picture-3.jpg',
    'images/picture-4.jpg',
    'images/picture-5.jpg',
  ],
  footer: {
    header: 'Contact Us',
    description:
      "Over our 7 year partnership, we've collaborated to create collections & stand alone statement pieces for numerous brands. <br /><br />Get in touch to see what we could do for you.",
    addressCodes: [
      {
        code: 'a2F0ZUBrZWxrYWNyZWF0aXZlLmNvbQ==',
        img: 'images/kat-a.png',
        name: 'Co Founder',
      },
      {
        code: 'a2VsbHlAa2Vsa2FjcmVhdGl2ZS5jb20=',
        img: 'images/kel-a.png',
        name: 'Co Founder',
      },
    ],
    disclaimer: 'Kelka Creative LTD is incorporated and registered in England and Wales with company number 15052236, our registered office is at 124 City Road, London, England, EC1V 2NX.',
  },
}));

export default useStore;
