/* eslint-disable consistent-return */
import { useEffect } from 'react';
import { useStore } from '../store';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useScroll = (callback, deps = []) => {
  const _lenis = useStore(({ lenis }) => lenis);
  useEffect(() => {
    if (!_lenis) return;
    _lenis.on('scroll', callback);
    _lenis.emit();

    return () => {
      _lenis.off('scroll', callback);
    };
  }, [_lenis, callback]);
};

export default useScroll;
