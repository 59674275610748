import React, { useEffect } from 'react';
import { sanitize } from '../../util/xss';
import atob from 'atob';
import { useStore } from '../../store';
import { Wrapper, Title, EmailAddresses, Description, Email, Name, Address, Disclaimer } from './Footer.styled';

export const Footer = (props) => {
  const footerStore = useStore((state) => state.footer);

  const onClick = (id, code) => {
    const href = document.getElementById(id);
    href.setAttribute("href", "mailto:".concat(atob(code)));
  }

  return (
    <Wrapper>
      <Title>{footerStore?.header}</Title>
      <Description dangerouslySetInnerHTML={{ __html: sanitize(footerStore?.description) }} />
      <EmailAddresses>
        {footerStore?.addressCodes.map((d, i) => {
          return (
            <Email key={`str-${i}`}>
              <Address id={`a-d-${i}`} onClick={() => onClick(`a-d-${i}`, d.code)}><img src={d.img} alt="text" /></Address>
              <Name>{d.name}</Name>
          </Email>
          )
        })}
      </EmailAddresses>
      <Disclaimer>
        {footerStore.disclaimer}
      </Disclaimer>  
    </Wrapper>
  );
};

export default Footer;
