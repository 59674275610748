import React, { useEffect, useRef } from 'react';
import Lenis from '@studio-freight/lenis';
import { useStore } from '../../store';
import gsap, { Power } from 'gsap';
import {ReactComponent as SVG} from '../svg/arc.svg';
import { Wrapper, Arc, Content } from './Layout.styled';

export const Layout = (props) => {
  const [lenis, setLenis] = useStore((state) => [state.lenis, state.setLenis]);
  const [intro,] = useStore((state) => [state.intro, state.setIntro]);
  const wrapperRef = useRef();

  useEffect(() => { 
    window.scrollTo(0, 0);
    const _lenis = new Lenis({
      lerp: 0.1,
      smoothWheel: true,
    });
    setLenis(_lenis);
    // eslint-disable-next-line consistent-return
    return () => {
      if (lenis) {
        lenis.destroy();
      }
      setLenis(null);
    };
  }, []);

  useEffect(() => {
    const raf = (time) => {
      lenis?.raf(time);
      requestAnimationFrame(raf);
    };
    requestAnimationFrame(raf);
  }, [lenis]);

  useEffect(() => {
  if(intro) {
    // gsap.to(window, {duration: 2, scrollTo: 400});
  }
  }, [intro]);

  // useEffect(() => {
  //   if (intro) {
  //     gsap.to(wrapperRef.current, {y: '-80vh', duration: 1});
  //   } 
  // }, [intro])
  return (
    <Wrapper ref={wrapperRef}>
      <Arc><SVG /></Arc>
      <Content>{props.children}</Content>
    </Wrapper>
  );
};

export default Layout;
