import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #BDBCB9;
  display: flex;
  flex-direction: column;
  height: auto;
  position: relative;
  padding: 0 5vw 5vh 5vw;
  width: 90vw;
`;

export const Title = styled.h2`
  font-family: 'brown-sugar';
  font-size: 52px;
  margin: 5vh 0;
  margin-block-end: 5vh;
  margin-block-start: 5vh;

  @media screen and (min-width: 650px) {
    font-size: 72px;
  }
`;

export const Description = styled.p`
  font-family: 'now-thin'; 
  font-weight: 700;
  font-size: 16px;

  @media screen and (min-width: 650px) {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

export const EmailAddresses = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 780px) {
    flex-direction: row;
    width: 50%;
  }
`;

export const Email = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.5vh;
  margin-right: 5vw;
  width: 100%;

  @media screen and (min-width: 780px) {
    margin-top: 5vh;
  }
`;

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  color: #4E7C76;
  font-family: 'now-thin'; 
  margin-top: 1.3vh;
  font-weight: 700;
  font-size: 16px;

  @media screen and (min-width: 650px) {
    font-size: 18px;
  }
`;

export const Address = styled.a`
  display: flex;
  flex-direction: column;
  
  img {
    max-width: 320px;
  }
`;

export const Disclaimer = styled.p`
  font-family: 'now-thin'; 
  font-weight: 700;
  font-size: 12px;
  margin-top: 2.5vh;
  line-height: 1.8;
  

  @media screen and (min-width: 650px) {
    font-size: 13px;
    margin-top: 5vh;
    margin-bottom: 2.5vh;
    line-height: 1.8;
  }
`;


