import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  pointer-events: none;
  overflow: hidden;
  opacity: ${(props) => props.opacity};

  @media (hover: none) {
    display: none;
  }
`;

export const CursorDetail = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 100%;
  border: ${(props) => (props.hover === 'true'  ? `2px solid #4E7C76` : `2px solid #4E7C76`)};
  background-color: #4E7C76;
  width: 10px;
  height: 10px;
  opacity: 1;
  transition: transform 600ms var(--ease-out-expo);
  ${(props) =>
    props.pointer === 'true' &&
    `
    transform: translate(-50%, -50%) scale(1);
  `}

  @media screen and (min-width: 900px) {
    border: ${(props) => (props.hover ? `2px solid #4E7C76` : `2px solid #4E7C76`)};
  }
`;

export const Container = styled.div`
  width: 30px;
`;
