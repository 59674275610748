import React from 'react';
import {ReactComponent as Logo} from '../svg/kelka-logo.svg';
import { Wrapper, LogoContainer, ScrollArrow } from './Header.styled';

export const Header = (props) => {
  return (
    <Wrapper>
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <ScrollArrow />
    </Wrapper>
  );
};

export default Header;
