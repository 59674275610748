import styled, { createGlobalStyle } from "styled-components";

import Headline from "./fonts/brown-sugar.woff2";
import Body from "./fonts/now-thin.woff2";

export const FontStyles = createGlobalStyle`
body {
  cursor: none;
}
@font-face {
  font-family: 'brown-sugar';
  src: url(${Headline}) format('woff2');
}

@font-face {
  font-family: 'now-thin';
  src: url(${Body}) format('woff2');
}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  position: relative;
  width: calc(100% - 10vw);
  padding: 0 5vw;

  @media screen and (min-width: 650px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
  }
`;
