import React, { useState, useEffect, useCallback, useRef } from 'react';
import useRefDimensions from '../../hooks/useRefDimensions';
import { lerp } from './lerp';
import { Wrapper, Container, CursorDetail } from './Cursor.styled';

// TODO: fix where cursor is visable
const Cursor = ({ hoverElements }) => {
  const cursorRef = useRef(null);
  const textRef = useRef(null);
  const cursorPosition = useRef({ x: 0, y: 0 });
  const cursorBounds = useRef(null);
  const renderedStyles = useRef({
    tx: { previous: 0, current: 0, amt: 0.03 },
    ty: { previous: 0, current: 0, amt: 0.03 },
  });
  const headerRef = useRef(document.getElementById('navbar-main'));
  const dimensions = useRefDimensions(headerRef?.current);
  const [hasMoved, setHasMoved] = useState(false);
  const [isGrab, setIsGrab] = useState(false);

  const onMouseMove = useCallback(
    ({ clientX, clientY }) => {
      cursorPosition.current = {
        x: clientX,
        y: clientY - dimensions.height,
      };
      setHasMoved(true);
    },
    [hasMoved],
  );

  const onMouseEnter = () => {
    setIsGrab(true);
  };
  const onMouseLeave = () => {
    setIsGrab(false);
  };

  const render = () => {
    renderedStyles.current.tx.current = cursorPosition.current.x;
    renderedStyles.current.ty.current = cursorPosition.current.y;
    Object.keys(renderedStyles.current).forEach((key) => {
      renderedStyles.current[key].previous = lerp(
        renderedStyles.current[key].previous,
        renderedStyles.current[key].current,
        renderedStyles.current[key].amt,
      );
    });

    // animate the cursor trail.
    cursorRef.current.style.transform = `translate3d(${renderedStyles.current.tx.previous}px, ${renderedStyles.current.ty.previous}px, 0)`;

    // loop...
    requestAnimationFrame(() => render());
  };

  useEffect(() => {
    window.addEventListener('mousemove', onMouseMove, false);
    cursorBounds.current = cursorRef.current.getBoundingClientRect();

    const elements = [...Array.from(document.querySelectorAll(`${hoverElements.toString()}`))];

    elements.forEach((element) => {
      element.addEventListener('mouseenter', onMouseEnter, false);
      element.addEventListener('mouseleave', onMouseLeave, false);
    });
    render();

    return () => {
      window.removeEventListener('mousemove', onMouseMove, false);
      elements.forEach((element) => {
        element.removeEventListener('mouseenter', onMouseEnter, false);
        element.removeEventListener('mouseleave', onMouseLeave, false);
      });
    };
  }, [hasMoved]);

  return (
    <Wrapper opacity={hasMoved ? 1 : 0}>
      <Container ref={cursorRef}>
        <CursorDetail hover={isGrab ? 'true' : 'false' } pointer={'false'} />
      </Container>
    </Wrapper>
  );
};

export default Cursor;
