import React, { useRef } from 'react';
import useScroll from '../../hooks/use-scroll';
import { Wrapper, Inner } from './ProgressBar.styled';

const ProgressBar = ({ color }) => {
  const progressBar = useRef(null);

  useScroll(({ scroll, limit }) => {
    const progress = scroll / limit;
    progressBar.current.style.transform = `scaleX(${progress})`;
  });

  return (
    <Wrapper>
      <Inner backgroundColor={color} ref={progressBar} />
    </Wrapper>
  );
};

export default ProgressBar;
