import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto;
  margin-top: 90vh;
  width: 100vw;
`;

export const Arc = styled.div`
  display: flex;
  transform: translate3d(0, 2px, 0);
  height: auto;
  width: 100%;

  svg {
    display: block;
    height: auto;
    width: 100%;
  }
`;

export const Content = styled.div`
  background-color: #F1EEEB;
  display: flex;
  flex-direction: column;
  height: auto;
  padding-bottom: 5vh;
  width: 100%;

  @media screen and (min-width: 650px) {
    flex-direction: row;
  }
`;

